import React from 'react';
import { deviceWidth } from 'src/utils/deviceWidth';
import {
  DEFAULT_IMAGE,
  IMAGE_LIMIT_L4,
  MEDIA_TYPES,
  TESTIMONIAL_IMAGE_POSITION,
} from '../Constants';
import { CSSProperties } from 'styled-components';
import {
  getMappedProductCasing,
  getMappedproductsTextSize,
  getMappedProductTextAlign,
  getMappedProductTextThickness,
} from '../../utils';

export function getImageLimit(isMobile) {
  if (!isMobile) {
    return IMAGE_LIMIT_L4.DESKTOP;
  }
  return deviceWidth <= 360 ? IMAGE_LIMIT_L4.MOBILE_XS : IMAGE_LIMIT_L4.MOBILE;
}

export function getImageClassesL4(isActive) {
  return isActive ? 'tw-border-b tw-border-solid tw-border-[#000000]' : 'tw-opacity-[70]';
}

export function getImageWrapperStyles(imageShape, index = 0): CSSProperties {
  const blobBorderRadius = [
    '30% 70% 70% 30% / 30% 47% 53% 70%',
    '54% 46% 70% 30% / 19% 79% 21% 81%',
    '87% 13% 60% 40% / 30% 93% 7% 70%',
    '67% 33% 16% 84% / 63% 52% 48% 37%',
    '72% 28% 69% 31% / 67% 57% 43% 33%',
  ];

  switch (imageShape) {
    case 'square':
      return { aspectRatio: 1 };
    case 'portraitTall':
      return { aspectRatio: 2 / 3 };
    case 'portraitShort':
      return { aspectRatio: 3 / 4 };
    case 'landscape':
      return { aspectRatio: 3 / 2 };
    case 'landscapeWide':
      return { aspectRatio: 4 / 3 };
    case 'circle':
      return {
        aspectRatio: 1,
        borderRadius: '9999px',
      };
    case 'blob':
      return { aspectRatio: 1, borderRadius: blobBorderRadius[index % 5] };
    case 'arch':
      return { aspectRatio: 3 / 4, borderRadius: '9999px 9999px 0px 0px' };
    default:
      return {};
  }
}

export function getViewableTestimonials({
  cardFrameConfig,
  isCarousel,
  isTestimonialListingPage,
  containerTracks,
}) {
  const allTestimonials = cardFrameConfig?.testimonialsList || [];

  const visibleTestimonials = allTestimonials?.filter?.((testimonial) =>
    testimonial?.isVisible !== undefined ? testimonial?.isVisible : true
  );

  // max rows for grid -> 2,  if not listing page.
  const shouldTruncateList = !isCarousel && !isTestimonialListingPage;

  return shouldTruncateList
    ? visibleTestimonials?.slice(0, containerTracks.numColumns * 2)
    : visibleTestimonials;
}

/**
 * This function return the updated list after the array rotation.
 * if limit is L, then the array would contain 2*L-1 element.
 * with L-1 elements for each side(left and right to the active image(testimonial) )
 */
export function getImageList({
  showRotation,
  totalItems,
  activeTestimonialIdx,
  viewAbleImageLimit,
  testimonialsData,
}) {
  let startIndex;
  let endIndex;

  // return the original array if rotation is not required
  if (!showRotation) {
    startIndex = 0;
    endIndex = totalItems - 1;
  } else {
    // negative indexes means elements from the end of array (-1 -> last element).
    startIndex = activeTestimonialIdx - (viewAbleImageLimit - 1);
    endIndex = activeTestimonialIdx + (viewAbleImageLimit - 1);
  }

  const imageList = [];

  // loop for generating new image list
  for (let i = startIndex; i <= endIndex; i++) {
    const testimonailIndex = (totalItems + i) % totalItems;
    const testimonialData = testimonialsData.at(testimonailIndex);

    imageList.push({
      mediaUrl: testimonialData?.authorMedia?.link || DEFAULT_IMAGE,
      mediaType: testimonialData?.authorMedia?.type || MEDIA_TYPES.IMAGE,
      index: testimonailIndex,
    });
  }

  return imageList;
}

/**
 *  function to scroll image in case of  L4  to the center of it's conatiner.
 */
export function scrollImageToConatinerCenter({
  element,
  instantScroll = false,
  scrollerRef,
  showLoader,
  setShowLoader,
}) {
  if (scrollerRef.current && element) {
    const container = scrollerRef.current as HTMLElement;

    // half of container width
    const containerHalfWidth = container.offsetWidth / 2;

    // position of container
    const containerRect = container.getBoundingClientRect();

    const conatinerCenter = containerRect.left + containerHalfWidth;

    // postition of element
    const itemRect = element.getBoundingClientRect();

    // position of element's center
    const itemCenter = (itemRect.left + itemRect.right) / 2;

    // displacement of element's center from the container's center (element's center - conatiner's center)
    const positionFromCenter = itemCenter - conatinerCenter;

    // adjust the scroll position of element.
    container.scrollTo({
      left: container.scrollLeft + positionFromCenter,
      behavior: instantScroll ? 'instant' : 'smooth',
    });
    showLoader && setShowLoader(false);
  }
}

export function getAuthorImagePositionInfo({ pos, isMobileAndL5 }) {
  let positionInfo = {
    withAuthorInfo: pos === TESTIMONIAL_IMAGE_POSITION.WITH_AUTHOR_INFO,
    top: pos === TESTIMONIAL_IMAGE_POSITION.TOP,
    bottom: pos === TESTIMONIAL_IMAGE_POSITION.BOTTOM,
    left: pos === TESTIMONIAL_IMAGE_POSITION.LEFT,
  };

  if (isMobileAndL5 && positionInfo.left) {
    positionInfo = {
      withAuthorInfo: false,
      top: true,
      bottom: false,
      left: false,
    };
  }
  return positionInfo;
}

export function getActiveTestimonialIndex({ carouselIndicatorConfigs, totalItems }) {
  return (carouselIndicatorConfigs.currentFocusedSectionItem - 1) % totalItems;
}

export function getAuthImageListMaxWidth({
  viewAbleImageLimit,
  isSmartShape,
  containerWidthSmartShape,
  imageSizeActive,
  imageSizeInactive,
  imageGap,
}) {
  const itemsExceptActive = viewAbleImageLimit - 1;
  const widthForInactiveItems =
    itemsExceptActive * (isSmartShape ? containerWidthSmartShape : imageSizeInactive);
  const widthForGapInBetween = itemsExceptActive * imageGap;
  const widthForActiveItem = isSmartShape ? containerWidthSmartShape : imageSizeActive;
  return widthForInactiveItems + widthForGapInBetween + widthForActiveItem;
}

// function to get Testimonial card wrapper styles
export function getWrapperStyles({
  showBorder,
  listPresentationConfig,
  isSelected,
  shouldAdjustCardPaddingX,
}) {
  const className = showBorder ? ' tw-border tw-border-solid ' : '';
  let style: React.CSSProperties = {};

  style = {
    ...style,
    borderColor: showBorder ? listPresentationConfig.borderColor : '',
    backgroundColor: showBorder ? listPresentationConfig.backgroundColor : '',
    ...(isSelected
      ? {
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: '#2B74D8',
          backgroundColor: '#2B74D80F',
        }
      : {}),
    ...(shouldAdjustCardPaddingX ? { paddingLeft: 20, paddingRight: 20 } : {}),
  };
  return { style, className };
}

export function getStylesAndUtilsFromConfig({ isMobile, cardFrameConfig }) {
  const textSizeKey = !isMobile ? 'textSizeDesktop' : 'textSizeMobile';
  const headSizelineKey = !isMobile ? 'headlineSizeDesktop' : 'headlineSizeMobile';
  const imageWidthKey = !isMobile ? 'imageSizeDesktop' : 'imageSizeMobile';

  const textAlignmentStyle = getMappedProductTextAlign(cardFrameConfig?.textAlign);

  const reviewStylesFromConfig = {
    ...getMappedproductsTextSize(cardFrameConfig?.[textSizeKey]),
    ...getMappedProductCasing(cardFrameConfig?.textCase),
    ...getMappedProductTextThickness(cardFrameConfig?.textThickness),
    ...textAlignmentStyle,
    color: cardFrameConfig.reviewColor,
  };

  const headlineStylesFromConfig = {
    ...getMappedProductCasing(cardFrameConfig?.headlineCase),
    ...getMappedproductsTextSize(cardFrameConfig?.[headSizelineKey]),
    ...getMappedProductTextThickness(cardFrameConfig?.headlineThickness),
    color: cardFrameConfig.headlineColor,
  };

  const imageWidth = cardFrameConfig?.[imageWidthKey];
  const isContentCenter = cardFrameConfig?.textAlign === 'center';
  const showRatings = cardFrameConfig?.showRatings;
  const showImage = cardFrameConfig?.showImage;

  return {
    reviewStylesFromConfig,
    headlineStylesFromConfig,
    textAlignmentStyle,
    imageWidth,
    isContentCenter,
    showRatings,
    showImage,
  };
}
